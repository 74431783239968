import React, { useState, useEffect } from "react";
import classes from "./courses.module.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BrudCrumsNextIcon,
  RoundAddPurpleIcon,
} from "../../../Assets/Icons/icons";
import { Button, Col, Row } from "react-bootstrap";
import { Typography, TextField, MenuItem, Card, Divider } from "@mui/material";
import Footer from "../../../Components/Footer";
import { pageLoader, setHeaderName } from "../../../Redux/actions/userActions";
import { getAllSubscriptions } from "../../../Redux/actions/subscriptionAction";
import AlertBox from "../../../Components/AlertBox";
import { getAllClasses } from "../../../Redux/actions/classActions";
import InputModal from "../../../Components/InputModal";
import CommanDropDown from "../../../Components/DragAndDrop";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
  FileUpload,
  GameFileUpload,
  addNewCourse,
  addNewGame,
  getAllGamesList,
  getOneCourse,
  updateCourse,
} from "../../../Redux/actions/courseAction";

const AddCourseScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subscriptionLevel, setSubscriptionLevel] = useState("select");
  const [errorAlert, setErrorAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [customSuccessAlert, setCustomSuccessAlert] = useState(false);
  const [demoVideo, setDemoVideo] = useState("");
  const [gameAddModal, setGameAddModal] = useState(false);
  const [type, setType] = useState("");
  const [classList, setClassList] = useState([]);
  const [gradeLevel, setGradeLevel] = useState("select");
  const [userEntry, setUserEntry] = useState({});
  const [levelList, setLevelList] = useState([
    "level 1",
    "level 2",
    "level 3",
    "level 4",
    "level 5",
  ]);
  const [gameName, setGameName] = useState("select");
  const [topic, setTopic] = useState("select");
  const [level, setLevel] = useState([]);
  const [callBackFunction, setCallBackFunction] = useState();
  const [coverImage, setCoverImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [levelFile, setLevelFile] = useState("");
  const [defaultError, setDefaultError] = useState("");
  const [subscriptionLevelError, setSubscriptionLevelError] = useState(false);
  const [gradeLevelError, setGradeLevelError] = useState(false);
  const [topicError, setTopicError] = useState(false);
  const [gameNameError, setGameNameError] = useState(false);
  const [levelError, setLeveError] = useState(false);
  const [defaultErrorAlert, setDefaultErrorAlert] = useState(false);
  const { subscriptionList } = useSelector((state) => state.subscriptionDatas);
  const { classesList } = useSelector((state) => state.classDatas);
  const { error, success, gamesList } = useSelector(
    (state) => state.courseDatas
  );
  const { oneCourse } = useSelector((state) => state.courseDatas);

  useEffect(() => {
    if (window.location.pathname.includes("edit")) {
      const path = window.location.pathname.split("/");
      dispatch(pageLoader(true));
      dispatch(getOneCourse(path[path.length - 1], setErrorAlert));
    }
  }, [dispatch]);

  useEffect(() => {
    if (oneCourse && window.location.pathname.includes("edit")) {
      setSubscriptionLevel(oneCourse.subscription_id);
      setGradeLevel(oneCourse.gradeLevel);
      setTopic(oneCourse.topic);
      setGameName(oneCourse.gameInfo._id);
      // setLevel(oneCourse?.level);
      setCoverImage(`${process.env.REACT_APP_API_URL}${oneCourse.cover_image}`);
    }
  }, [oneCourse]);

  const handleSetGameFile = (level) => {
    console.log(level);
    const file = oneCourse?.level.find((item) => item.level_name === level);
    console.log("file", file);
    setLevelFile(`${process.env.REACT_APP_API_URL}${file?.level_file}`);
    setFileName(file?.file_name);
  };

  const handleNavigateCourse = () => {
    dispatch(setHeaderName("Courses/Games"));
    navigate("/cources");
  };

  const ModalClose = () => {
    setGameAddModal(false);
    setGameName("select");
    setLevel("select");
  };

  const getClassList = () => {
    const array = classesList.map((item, index) => {
      return item.grade;
    });
    const resultArray = array.filter((value, index, self) => {
      return value !== undefined && value !== "";
    });
    setClassList(resultArray);
  };

  useEffect(() => {
    getClassList();
  }, [classesList]);

  const handleGameAdd = () => {
    setGameName("select");
    setGameAddModal(true);
    setType("addGame");
  };

  const AddGamesInServer = (userEntry) => {
    console.log("UserEntry", userEntry);
    dispatch(addNewGame(userEntry, setCustomSuccessAlert, setErrorAlert));
  };

  const AddLevelInServer = (userEntry) => {
    console.log("UserEntry", userEntry);
    const array = [...levelList];
    if (
      levelList.some(
        (level) => level.toLowerCase() === userEntry.name.toLowerCase()
      )
    ) {
      setDefaultError("Level already exists");
      setDefaultErrorAlert(true);
      return;
    } else {
      array.push(userEntry.name);
      console.log("array ==>", array);
      setLevelList(array);
    }
  };

  const handleAddLevel = () => {
    setLevel("select");
    setGameAddModal(true);
    setType("addLevel");
  };

  useEffect(() => {
    dispatch(getAllSubscriptions(setErrorAlert));
    dispatch(getAllClasses(setErrorAlert, "", "all"));
    dispatch(getAllGamesList());
  }, [dispatch]);

  const CloseAlert = () => {
    setErrorAlert(false);
    setSuccessAlert(false);
    setDefaultErrorAlert(false);
    setDefaultError("");
    setCustomSuccessAlert(false);
  };

  const handleSubmit = async () => {
    if (subscriptionLevel === "select") {
      setSubscriptionLevelError(true);
    }
    if (gradeLevel === "select") {
      setGradeLevelError(true);
    }
    if (topic === "select") {
      setTopicError(true);
    }
    if (gameName === "select") {
      setGameNameError(true);
    }
    if (level === "select") {
      setLeveError(true);
    }
    if (
      subscriptionLevel !== "select" &&
      gradeLevel !== "select" &&
      topic !== "select" &&
      gameName !== "select" &&
      level !== "select"
    ) {
      if (window.location.pathname.includes("edit")) {
        let coverImageUrl,
          levelFileUrl,
          fileUrl1 = null,
          fileUrl2 = null;
        dispatch(pageLoader(true));
        if (typeof coverImage === "object") {
          coverImageUrl = await dispatch(FileUpload(coverImage));
          fileUrl1 = `/${coverImageUrl?.destination}${coverImageUrl?.filename}`;
        } else if (typeof coverImage === "string") {
          fileUrl1 = coverImage;
        }
        if (typeof levelFile === "object") {
          levelFileUrl = await dispatch(GameFileUpload(levelFile));
          fileUrl2 = `/${levelFileUrl?.destination}${levelFileUrl?.filename}`;
        } else if (typeof levelFile === "string") {
          fileUrl2 = levelFile;
        }
        console.log("coverImageUrl", coverImageUrl);
        console.log("levelFileUrl", levelFileUrl);
        if (!fileUrl1 && !fileUrl2) {
          dispatch(pageLoader(false));
          setDefaultErrorAlert(true);
          setDefaultError("Please Select Cover Image and Level File");
          return;
        }
        if (!fileUrl1 && fileUrl2) {
          dispatch(pageLoader(false));
          setDefaultErrorAlert(true);
          setDefaultError("Please Select Cover Image");
          return;
        }
        if (!fileUrl2 && fileUrl1) {
          dispatch(pageLoader(false));
          setDefaultErrorAlert(true);
          setDefaultError("Please Select Level File");
          return;
        }
        if (fileUrl1 && fileUrl2) {
          const changedArray = oneCourse?.level.map((item) => {
            if (item.level_name === level) {
              return {
                level_name: level,
                file: fileUrl2,
                file_name: levelFileUrl?.originalname || fileName,
              };
            } else {
              return item;
            }
          });
          const userEntry = {
            subscription_id: subscriptionLevel,
            gradeLevel: gradeLevel,
            topic: topic,
            game_name: gameName,
            cover_image: fileUrl1,
            level: changedArray,
          };
          dispatch(
            updateCourse(
              oneCourse?._id,
              userEntry,
              setSuccessAlert,
              setErrorAlert
            )
          );
        }
      } else {
        let coverImageUrl,
          levelFileUrl,
          fileUrl1 = null,
          fileUrl2 = null;
        dispatch(pageLoader(true));
        if (typeof coverImage === "object") {
          coverImageUrl = await dispatch(FileUpload(coverImage));
          fileUrl1 = `/${coverImageUrl?.destination}${coverImageUrl?.filename}`;
        } else if (typeof coverImage === "string") {
          fileUrl1 = coverImage;
        }
        if (typeof levelFile === "object") {
          levelFileUrl = await dispatch(GameFileUpload(levelFile));
          fileUrl2 = `/${levelFileUrl?.destination}${levelFileUrl?.filename}`;
        } else if (typeof levelFile === "string") {
          fileUrl2 = levelFile;
        }
        console.log("coverImageUrl", coverImageUrl);
        console.log("levelFileUrl", levelFileUrl);
        if (!fileUrl1 && !fileUrl2) {
          dispatch(pageLoader(false));
          setDefaultErrorAlert(true);
          setDefaultError("Please Select Cover Image and Level File");
          return;
        }
        if (!fileUrl1 && fileUrl2) {
          dispatch(pageLoader(false));
          setDefaultErrorAlert(true);
          setDefaultError("Please Select Cover Image");
          return;
        }
        if (!fileUrl2 && fileUrl1) {
          dispatch(pageLoader(false));
          setDefaultErrorAlert(true);
          setDefaultError("Please Select Level File");
          return;
        }
        if (fileUrl1 && fileUrl2) {
          const userEntry = {
            subscription_id: subscriptionLevel,
            gradeLevel: gradeLevel,
            topic: topic,
            game_name: gameName,
            cover_image: fileUrl1,
            level: [
              {
                level_name: level,
                file: fileUrl2,
                file_name: levelFileUrl?.originalname || fileName,
              },
            ],
          };
          dispatch(addNewCourse(userEntry, setSuccessAlert, setErrorAlert));
        }
      }
    }
  };

  return (
    <div>
      <div className={classes.brudcrumbs}>
        <span
          className={classes.TableHeaderText}
          onClick={() => handleNavigateCourse()}
        >
          Courses/Games
        </span>
        <span>
          <BrudCrumsNextIcon />
        </span>
        <span className={classes.TableBodyText}>Add Games</span>
      </div>
      <ValidatorForm
        onSubmit={handleSubmit}
        useref="form"
        className={`mt-3 ${classes.formContainer}`}
      >
        <Row className="mt-3">
          <Col md={4}>
            <Typography className={classes.formLable}>
              Subscription Level
            </Typography>
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                marginBottom: "15px",
              }}
              select
              id="subscriptionLevelInput"
              fullWidth
              placeholder="Select subscription level"
              className={
                subscriptionLevel === "select"
                  ? `${classes.TextInput} ${classes.defaultMenuItem}`
                  : classes.TextInput
              }
              value={subscriptionLevel}
              onChange={(e) => {
                setSubscriptionLevel(e.target.value);
                if (e.target.value !== "select") {
                  setSubscriptionLevelError(false);
                }
              }}
              size="small"
              error={subscriptionLevelError}
              helperText={
                subscriptionLevelError && "Subscription Level is required"
              }
            >
              <MenuItem
                key={"select"}
                value={"select"}
                className={classes.defaultMenuItem}
                style={{ display: "none" }}
              >
                Select subscription level
              </MenuItem>
              {subscriptionList &&
                subscriptionList.length > 0 &&
                subscriptionList.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item._id}>
                      {item.subscription_name}
                    </MenuItem>
                  );
                })}
            </TextField>
          </Col>
          <Col md={4}>
            <Typography className={classes.formLable}>Grade Level</Typography>
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                marginBottom: "15px",
              }}
              select
              id="gradeInput"
              fullWidth
              placeholder="Select Grade Level"
              className={
                gradeLevel === "select"
                  ? `${classes.TextInput} ${classes.defaultMenuItem}`
                  : classes.TextInput
              }
              value={gradeLevel}
              onChange={(e) => {
                setGradeLevel(e.target.value);
                if (e.target.value !== "select") {
                  setGradeLevelError(false);
                }
              }}
              size="small"
              error={gradeLevelError}
              helperText={gradeLevelError && "Grade Level is required"}
            >
              <MenuItem
                key={"select"}
                value={"select"}
                className={classes.defaultMenuItem}
                style={{ display: "none" }}
              >
                Select Grade Level
              </MenuItem>
              {classList.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={item}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.charAt(0).toUpperCase() +
                      item.slice(1).toLowerCase().replaceAll("_", " ")}
                  </MenuItem>
                );
              })}
            </TextField>
          </Col>
          <Col md={4} style={{ textAlign: "left" }}>
            <Typography className={classes.formLable}>Select Topic</Typography>
            <TextField
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                marginBottom: "15px",
              }}
              select
              id="topicInput"
              placeholder="Select Topic"
              className={
                topic === "select"
                  ? `${classes.TextInput} ${classes.defaultMenuItem} ${classes.topicDropdown}`
                  : `${classes.TextInput} ${classes.topicDropdown}`
              }
              value={topic}
              onChange={(e) => {
                setTopic(e.target.value);
                if (e.target.value !== "select") {
                  setTopicError(false);
                }
              }}
              size="small"
              error={topicError}
              helperText={topicError && "Topic is required"}
            >
              <MenuItem
                key={"select"}
                value={"select"}
                className={classes.defaultMenuItem}
                style={{ display: "none" }}
              >
                Select Topic
              </MenuItem>
              {[
                "computer_programming",
                "algorithms",
                "digital_literacy",
                "safety",
                "computer_programming-2",
                "information_technology",
              ].map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={item}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.replaceAll("_", " ")}
                  </MenuItem>
                );
              })}
            </TextField>
          </Col>
        </Row>
        <Card style={{ padding: "20px" }} className="mt-2">
          <Typography className={classes.headingText}>Game & Topic</Typography>
          <Row className="mt-3">
            <Col md={4}>
              <Typography className={classes.formLable}>Game Name</Typography>
              <TextField
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  marginBottom: "15px",
                }}
                select
                id="subscriptionLevelInput"
                fullWidth
                placeholder="Select subscription level"
                className={
                  gameName === "select"
                    ? `${classes.TextInput} ${classes.defaultMenuItem}`
                    : classes.TextInput
                }
                value={gameName}
                onChange={(e) => {
                  setGameName(e.target.value);
                  if (e.target.value !== "select") {
                    setGameNameError(false);
                  }
                }}
                size="small"
                error={gameNameError}
                helperText={gameNameError && "Game Name is required"}
              >
                <MenuItem
                  key={"select"}
                  value={"select"}
                  className={classes.defaultMenuItem}
                  style={{ display: "none" }}
                >
                  Select existing game
                </MenuItem>
                <MenuItem
                  style={{ marginTop: "7px" }}
                  value={""}
                  onClick={() => handleGameAdd()}
                >
                  <Button variant="text" className={classes.addText}>
                    <RoundAddPurpleIcon />
                    <span>Add Game/Topic</span>
                  </Button>
                </MenuItem>
                <Divider style={{ margin: 0 }} />
                {gamesList.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item._id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Col>
            <Col md={4}>
              <Typography className={classes.formLable}>
                Upload Cover image
              </Typography>
              {/* <div className={classes.dragAndDropArea}>
              <Button className={classes.browseButton}>Browse...</Button>
              <Typography className={classes.dragDropText}>
                or drop file here
              </Typography>
            </div> */}
              <CommanDropDown
                image={coverImage}
                callback={setCoverImage}
                type={"coverImage"}
              />
            </Col>
            <Col md={4}>
              <Typography className={classes.formLable}>
                Add Demo Video
              </Typography>
              {/* <div className={classes.dragAndDropArea}>
              <Button className={classes.browseButton}>Browse...</Button>
              <Typography className={classes.dragDropText}>
                or drop file here
              </Typography>
            </div> */}
              <CommanDropDown
                image={demoVideo}
                callback={setDemoVideo}
                type={"demoVideo"}
              />
            </Col>
          </Row>
        </Card>
        {window.location.pathname.includes("edit") ? (
          <Card className="mt-3 mb-5" style={{ padding: "20px" }}>
            <Typography className={classes.headingText}>
              Level Details
            </Typography>
            <Row className="mt-3">
              <Col md={4}>
                <Typography className={classes.formLable}>
                  Level Name
                </Typography>
                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                    height: "40px",
                  }}
                  select
                  id="subscriptionLevelInput"
                  fullWidth
                  placeholder="Select subscription level"
                  className={
                    level === "select"
                      ? `${classes.TextInput} ${classes.defaultMenuItem}`
                      : classes.TextInput
                  }
                  value={level}
                  onChange={(e) => {
                    setLevel(e.target.value);
                    if (e.target.value !== "select") {
                      setLeveError(false);
                      handleSetGameFile(e.target.value);
                    }
                  }}
                  size="small"
                  error={levelError}
                  helperText={levelError && "Level Name is required"}
                >
                  <MenuItem
                    key={"select"}
                    value={"select"}
                    className={classes.defaultMenuItem}
                    style={{ display: "none" }}
                  >
                    Select level
                  </MenuItem>
                  {/* <MenuItem
                    style={{ marginTop: "7px" }}
                    onClick={() => handleAddLevel()}
                  >
                    <Button variant="text" className={classes.addText}>
                      <RoundAddPurpleIcon />
                      <span>Add Level</span>
                    </Button>
                  </MenuItem> */}
                  {oneCourse?.level.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.level_name}>
                        {item.level_name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
              <Col md={4}>
                <Typography className={classes.formLable}>
                  Upload level File*
                </Typography>
                <CommanDropDown
                  image={levelFile}
                  callback={setLevelFile}
                  type={"levelFile"}
                  name={fileName}
                  text={"Supported format Zip only"}
                />
              </Col>
            </Row>
          </Card>
        ) : (
          <Card className="mt-3 mb-5" style={{ padding: "20px" }}>
            <Typography className={classes.headingText}>
              Level Details
            </Typography>
            <Row className="mt-3">
              <Col md={4}>
                <Typography className={classes.formLable}>
                  Level Name
                </Typography>
                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    marginBottom: "15px",
                    height: "40px",
                  }}
                  select
                  id="subscriptionLevelInput"
                  fullWidth
                  placeholder="Select subscription level"
                  className={
                    level === "select"
                      ? `${classes.TextInput} ${classes.defaultMenuItem}`
                      : classes.TextInput
                  }
                  value={level}
                  // onChange={(e) => {
                  //   const updatedInputs = [...level];
                  //   updatedInputs[index].level_name = e.target.value;
                  //   setLevel(e.target.value);
                  //   if (e.target.value !== "select") {
                  //     setLeveError(false);
                  //   }
                  // }}
                  onChange={(e) => {
                    setLevel(e.target.value);
                    if (e.target.value !== "select") {
                      setLeveError(false);
                    }
                  }}
                  size="small"
                  error={levelError}
                  helperText={levelError && "Level Name is required"}
                >
                  <MenuItem
                    key={"select"}
                    value={"select"}
                    className={classes.defaultMenuItem}
                    style={{ display: "none" }}
                  >
                    Select level
                  </MenuItem>
                  <MenuItem
                    style={{ marginTop: "7px" }}
                    onClick={() => handleAddLevel()}
                  >
                    <Button variant="text" className={classes.addText}>
                      <RoundAddPurpleIcon />
                      <span>Add Level</span>
                    </Button>
                  </MenuItem>
                  {levelList.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Col>
              <Col md={4}>
                <Typography className={classes.formLable}>
                  Upload level File*
                </Typography>
                <CommanDropDown
                  image={levelFile}
                  callback={setLevelFile}
                  type={"levelFile"}
                  name={fileName}
                />
              </Col>
            </Row>
          </Card>
        )}
        <Footer
          callback={handleNavigateCourse}
          edit={window.location.pathname.includes("edit")}
        />
      </ValidatorForm>
      {gameAddModal && (
        <InputModal
          modalClose={ModalClose}
          type={type}
          callback={
            type === "addGame"
              ? AddGamesInServer
              : type === "addLevel"
              ? AddLevelInServer
              : null
          }
          setUserEntry={setUserEntry}
        />
      )}
      {errorAlert && error && (
        <AlertBox type="error" message={error} stateName={CloseAlert} />
      )}
      {defaultError && defaultErrorAlert && (
        <AlertBox type="error" message={defaultError} stateName={CloseAlert} />
      )}
      {customSuccessAlert && success && (
        <AlertBox type="success" message={success} stateName={CloseAlert} />
      )}
      {successAlert && success && (
        <AlertBox
          type="success"
          message={success}
          stateName={CloseAlert}
          callback={handleNavigateCourse}
        />
      )}
    </div>
  );
};

export default AddCourseScreen;
